<template>
  <section class="w-100">
    <div class="d-flex justify-content-between mb-05">
      <component :clearable="false" :is="vue_select" :options="filter" label="label" v-model="filter_selected" class="mb-1 select-filter-tweeter-sidebar-analytics"></component>

      <div class="d-flex align-items-center">
        <b-button @click="prev" variant="outline-secondary-custom-analysis"
          class="outline-secondary-custom-analysis"><feather-icon icon="ArrowLeftIcon" size="18"></feather-icon></b-button>
        <b-button @click="next" class="ml-1 outline-secondary-custom-analysis"
          variant="outline-secondary-custom-analysis"><feather-icon icon="ArrowRightIcon" size="18"></feather-icon></b-button>
      </div>
    </div>
    <div class="grid-tweets">

      <div class="carousel" ref="carousel">
        <article class="card-tweet" v-for="i, index in real_tweets" :key="index">
          <p>{{ i.basic.title }}</p>

          <div>
            <p class="mb-0">{{ formatDate(i.basic.time) }}</p>
  
            <div class="border-top container-metrics">
              <div class="scrolly">
                <span class="span-container">
                  <feather-icon icon="MessageCircleIcon" class="icon-feat"></feather-icon>
                  <span>{{ getFormat(i.metrics.reply_count) }}</span>
                </span>
  
                <span class="span-container">
                  <feather-icon icon="RepeatIcon" class="icon-feat"></feather-icon>
                  <span>{{ getFormat(i.metrics.retweet_count) }}</span>
                </span>
  
                <span class="span-container">
                  <feather-icon icon="HeartIcon" class="icon-feat"></feather-icon>
                  <span>{{ getFormat(i.metrics.favorite_count) }}</span>
                </span>
              </div>
            </div>
          </div>
        </article>
      </div>

    </div>
  </section>
</template>

<script>
import { getFormat } from '@/libs/utils/formats';
import { BButton } from 'bootstrap-vue';

export default {
  components: {
    BButton
  },
  data() {
    return {
      getFormat,
      vue_select: null,
      filter: [
        { type: 'recent', label: 'Más reciente' },
        { type: 'more_retweets', label: 'Más retuits' },
        { type: 'responses', label: 'Respuestas' },
        { type: 'more_likes', label: 'Con más "me gusta"' },
      ],
      filter_selected: { type: 'recent', label: 'Mas reciente' },
      real_tweets: [],
      scrollPosition: 0,
      card_width: 0
    };
  },
  props: {
    tweets: {
      type: Object,
      required: true
    }
  },
  computed: {
  },
  async mounted() {
    // Importing vue-select library
    this.vue_select = (await import('vue-select')).default;

    // Logic for going back and forward
    this.calculateCardWidth();
    window.addEventListener("resize", this.calculateCardWidth);
  },

  created() {
    // Getting data correct and converting to array
    if (this.tweets.status.code === 'OK') {
      for (const i in this.tweets.data) {
        this.real_tweets.push(this.tweets.data[i]);
      }
    }
  },
  beforeDestroy() {
    // Quit event on destroy component
    window.removeEventListener("resize", this.calculateCardWidth);
  },
  methods: {
    // Special format date for tweets
    formatDate(dateString) {
      const date = new Date(dateString);

      const timeFormatter = new Intl.DateTimeFormat("es-MX", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
      const time = timeFormatter.format(date);

      const dateFormatter = new Intl.DateTimeFormat("es-MX", {
        day: "numeric",
        month: "short",
        year: "numeric",
      });
      const formattedDate = dateFormatter.format(date);
      return `${time} · ${formattedDate}`;
    },

    calculateCardWidth() {
      const card = this.$refs.carousel?.querySelector(".card-tweet");
      if (card) this.card_width = card.offsetWidth;
    },
    //Actions for carousel going back and forward 
    prev() {
      this.$refs.carousel.scrollBy({ left: (-this.card_width * 3) - 30, behavior: "smooth" });
    },
    next() {
      this.$refs.carousel.scrollBy({ left: (this.card_width * 3) + 30, behavior: "smooth" });
    },
    // Sorts actions
    moreRecent() {
      this.real_tweets = this.real_tweets.sort((a, b) => new Date(b.basic.time) - new Date(a.basic.time));
    },
    moreRetweets() {
      this.real_tweets = this.real_tweets.sort((a, b) => b.metrics.retweet_count - a.metrics.retweet_count);
    },
    moreResponses() {
      this.real_tweets = this.real_tweets.sort((a, b) => b.metrics.reply_count - a.metrics.reply_count);
    },
    moreLiked() {
      this.real_tweets = this.real_tweets.sort((a, b) => b.metrics.favorite_count - a.metrics.favorite_count) 
    }

  },
  watch: {
    filter_selected(val) {
      if (val.type === 'recent') {
        this.moreRecent();
      } else if (val.type === 'more_retweets') {
        this.moreRetweets();
      } else if (val.type === 'responses') {
        this.moreResponses();
      } else if (val.type === 'more_likes') {
        this.moreLiked();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.grid-tweets {

  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;

  .carousel {
    display: flex;
    overflow: hidden;
    scroll-behavior: smooth;
    width: 100%;
    gap: 10px;

    .card-tweet {
      font-family: "avenir-medium";
      background-color: white;
      box-shadow: 0 1px 4px #0b14260a;
      border: 1px solid #E2E8F0;
      padding: 15px;
      border-radius: 1.3em;
      white-space: normal;
      word-wrap: break-word;
      // Calculation of width for 3 cards tweets even padding 
      min-width: calc((100% / 3) - (10px * 2 / 3));
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .container-metrics {
        width: 100%;
        display: flex;
        padding: 5px 0 0 0;
        margin-top: 0.5em;

        .span-container {
          width: auto;
          display: flex;
          align-items: center;
          margin-right: 5px;
          position: relative;

          .icon-feat {
            margin-right: 4px;
          }
        }

        .scrolly {
          display: flex;
          overflow: scroll;
        }
      }
    }
  }
}

.outline-secondary-custom-analysis {
  background: white;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 4px;
  padding: 5px !important;
  border-radius: 8px;
}
.mb05 {
  margin-bottom: 0.5rem;
}
</style>

<style>
.select-filter-tweeter-sidebar-analytics {
  width: 210px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid rgb(224, 224, 224);
}
</style>